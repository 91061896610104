<template>
  <div id="callback" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Callback',
  computed: {
    ...mapState({
      redirectPath: state => state.auth.redirect
    })
  },
  /**
   * Callback component for oauth redirect.
   *
   * Redirects user to Root component by
   * default, unless a redirect path has been
   * set in the store.
   */
  async created () {
    try {
      await this.$store.dispatch('auth/createAuthSession')
      await this.$store.dispatch('auth/fetchAccount')
      let location = { name: 'Root' }
      if (this.redirectPath) {
        location = { path: this.redirectPath }
      }
      this.$router.push(location)
    } catch (error) {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
